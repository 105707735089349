import React, { useState, useEffect, useContext } from 'react'
import Amplify, { Auth } from 'aws-amplify'
import { CognitoContext } from '../context'

const isBrowser = typeof window !== `undefined`

if (isBrowser) {
  try {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: process.env.GATSBY_AWS_REGION,
        identityPoolId: process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID,
        userPoolId: process.env.GATSBY_AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.GATSBY_AWS_COGNITO_WEB_ID,
      },
    })
  } catch (err) {
    console.error('error:', err)
  }
}

// from social index
export const useCognitoAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    (typeof localStorage !== 'undefined' &&
      localStorage.getItem('initialTime')) ||
      false
  )
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [user, setUser] = useState(
    (typeof localStorage !== 'undefined' && localStorage.getItem('user')) ||
      null
  )

  useEffect(() => {
    if (!isBrowser) {
      return
    }
    const prevTime = localStorage.getItem('initialTime')
    const expiration = localStorage.getItem('expirationTime')
    const currentTime = new Date().getTime()
    if (currentTime - prevTime > expiration) {
      signOut(setIsAuthenticated, setUser)
    } else {
      try {
        // check for current session in session storage
        Auth.currentSession()
          .then(cognitoUserSession => {
            setUser(cognitoUserSession)
            setIsAuthenticated(true)
            setIsLoading(false)
            setIsError(false)
          })
          .catch(error => {
            if (error !== 'No current user') {
              setIsError(true)
              console.error({ error })
            }
            setUser(null)
            setIsLoading(false)
          })
      } catch (error) {
        setIsError(true)
        console.error({ error })
      }
    }
  }, [])

  return {
    user,
    isAuthenticated,
    isLoading,
    isError,
    setIsAuthenticated,
    setUser,
  }
}

export const signUp = async (username, password) => {
  try {
    const user = await Auth.signUp({
      username,
      password,
      attributes: {
        email: username,
      },
    })
    localStorage.setItem('newSignup', username)
    return user
  } catch (error) {
    return error
  }
}

export const confirmSignUp = async (username, code) => {
  try {
    const confirmation = await Auth.confirmSignUp(username, code)
    return confirmation
  } catch (error) {
    console.error('error confirming sign up', error)
  }
}

export const signOut = async (authenticate, userFunc) => {
  try {
    await Auth.signOut()
    authenticate(false)
    userFunc(null)
    localStorage.clear()
  } catch (e) {
    return false
  }
}

export const useAuth = () => {
  return useContext(CognitoContext)
}

export const CognitoAuthenticationProvider = ({ children }) => {
  const auth = useCognitoAuth()
  return (
    <CognitoContext.Provider value={{ auth }}>
      {children}
    </CognitoContext.Provider>
  )
}
