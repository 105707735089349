import './src/styles/global.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import ReactDOM from 'react-dom'

export { wrapRootElement } from './apollo/wrap-root'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/**
 * Initialize Google Analytics
 */
// export const onClientEntry = () => {
//   if (process.env.NODE_ENV === 'production') {
//     GoogleAnalytics.initialize(siteMetadata.googleAnalyticsId)
//   }
//   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//   if (!(`IntersectionObserver` in window)) {
//     import(`intersection-observer`)
//   }
// }

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
