import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { CognitoAuthenticationProvider } from '@hooks/useAuth'
import { client } from './client'

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <CognitoAuthenticationProvider>{element}</CognitoAuthenticationProvider>
  </ApolloProvider>
)
