import React from 'react'

const PreviewContext = React.createContext()
const PreviewAssetsContext = React.createContext()
const CognitoContext = React.createContext(null)
const EmvVisualizerContext = React.createContext({})

export {
  PreviewContext,
  PreviewAssetsContext,
  CognitoContext,
  EmvVisualizerContext,
}
